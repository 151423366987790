import React from 'react'
import logo_dark from '../../assets/images/igchamp/ig-champ-logo.png';
import logo_light from '../../assets/images/igchamp/ig-champ-logo.png';
import '../../assets/libs/@mdi/font/css/materialdesignicons.min.css';
import '../../assets/css/tailwind.css';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'

export default function Navbar(props) {
    let { navClass, navJustify } = props;
    let [isMenu, setisMenu] = useState(false);
    let [manu , setManu] = useState('');
    let location = useLocation();
    
    useEffect(()=>{
        let current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setManu(current)

        function windowScroll() {
            const navbar = document.getElementById("topnav");
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                if (navbar !== null) {
                    navbar?.classList.add("nav-sticky");
                }
            } else {
                if (navbar !== null) {
                    navbar?.classList.remove("nav-sticky");
                }
            }
        }
        window.addEventListener("scroll", windowScroll);
        window.scrollTo(0, 0)
        return () => {
            window.removeEventListener('scroll', windowScroll);
        };

    },[location.pathname.substring(location.pathname.lastIndexOf('/') + 1)])
    

    const toggleMenu = () => {
        setisMenu(!isMenu);
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    };


    return (
        <nav id="topnav" className={`defaultscroll ${navClass === "nav-light" ? '' : navClass === "nav-sticky" ?
        'bg-white dark:bg-slate-900' : ''}`}>
        <div className="container relative">
            {
                navClass === "nav-light" ?
                    <Link className="logo w-1/2" to="/index">
                        <span className="inline-block dark:hidden">
                            <img src={logo_dark} className="l-dark" height="24" alt="" />
                            <img src={logo_light} className="l-light" height="24" alt="" />
                        </span>
                        <img src={logo_light} height="24" className="hidden dark:inline-block" alt="" />
                    </Link>
                    :
                    <Link className="logo w-48" to="/index">
                        <img src={logo_dark} className="inline-block dark:hidden" alt="" />
                        <img src={logo_light} className="hidden dark:inline-block" alt="" />
                    </Link>
            }

            <div className="menu-extras">
                <div className="menu-item">
                    <Link to="#" className={`navbar-toggle ${isMenu ? 'open' : ''}`} id="isToggle" onClick={() => toggleMenu()}>
                        <div className="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </Link>
                </div>
            </div>
           
            <div id="navigation" style={{ display: isMenu ? 'block' : 'none' }}>
                <ul className={`navigation-menu ${navClass} ${navJustify}`}>
                    <li className={manu === "index" || "" ? "active" : ""}><Link to="/index" className="sub-menu-item">Home</Link></li>

                    <li className={`${["portfolio-modern-two", "portfolio-modern-three","portfolio-modern-four","portfolio-modern-five","portfolio-modern-six","portfolio-classic-two","portfolio-classic-three","portfolio-classic-four","portfolio-classic-five","portfolio-classic-six","portfolio-creative-two","portfolio-creative-three","portfolio-creative-four","portfolio-creative-five","portfolio-creative-six","portfolio-masonry-two","portfolio-masonry-three","portfolio-masonry-four","portfolio-masonry-five","portfolio-masonry-six","portfolio-detail-one","portfolio-detail-two","portfolio-detail-three",'portfolio-detail-four'].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`} >
                        <Link to="#">Services</Link><span className="menu-arrow"></span>
                        <ul className="submenu megamenu">
                            <li>
                                <ul>
                                    <li className="megamenu-head">Instagram Services</li>
                                    <li className={manu === "buy-instagram-followers" || "" ? "active" : ""}><Link to="/buy-instagram-followers" className="sub-menu-item">Buy Instagram Followers</Link></li>
                                    <li className={manu === "buy-instagram-likes" || "" ? "active" : ""}><Link to="/buy-instagram-likes" className="sub-menu-item">Buy Instagram Likes</Link></li>
                                    <li className={manu === "buy-instagram-views" || "" ? "active" : ""}><Link to="/buy-instagram-views" className="sub-menu-item">Buy Instagram Views</Link></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li className="megamenu-head">Tiktok Services</li>
                                    <li className={manu === "buy-tiktok-followers" || "" ? "active" : ""}><Link to="/buy-tiktok-followers" className="sub-menu-item">Buy TikTok Followers</Link></li>
                                    <li className={manu === "buy-tiktok-likes" || "" ? "active" : ""}><Link to="/buy-tiktok-likes" className="sub-menu-item">Buy TikTok Likes</Link></li>
                                    <li className={manu === "buy-tiktok-views" || "" ? "active" : ""}><Link to="/buy-tiktok-views" className="sub-menu-item">Buy TikTok Views</Link></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li className="megamenu-head">Youtube Services</li>
                                    <li className="megamenu-head">COMING SOON</li>
                                    {/*<li className={manu === "buy-youtube-subscriber" || "" ? "active" : ""}><Link
                                        to="/buy-youtube-subscriber" className="sub-menu-item">Buy Youtube
                                        Subscribers</Link></li>
                                    <li className={manu === "buy-youtube-likes" || "" ? "active" : ""}><Link
                                        to="/buy-youtube-likes" className="sub-menu-item">Buy Youtube Likes</Link>
                                    </li>
                                    <li className={manu === "buy-youtube-views" || "" ? "active" : ""}><Link
                                        to="/buy-youtube-views" className="sub-menu-item">Buy Youtube Views</Link>
                                    </li>
                                    <li className={manu === "buy-youtube-watch-time" || "" ? "active" : ""}><Link
                                        to="/buy-youtube-watch-time" className="sub-menu-item">Buy Youtube Watch Time</Link></li>*/}
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li className="megamenu-head">Facebook Services</li>
                                    <li className="megamenu-head">COMING SOON</li>
                                    {/*<li className={manu === "buy-facebook-followers" || "" ? "active" : ""}><Link
                                        to="/buy-facebook-followers" className="sub-menu-item">Buy Facebook Followers</Link>
                                    </li>
                                    <li className={manu === "buy-facebook-likes" || "" ? "active" : ""}><Link
                                        to="/buy-facebook-likes" className="sub-menu-item">Buy Facebook Post Likes</Link></li>
                                    <li className={manu === "buy-facebook-views" || "" ? "active" : ""}><Link
                                        to="/buy-facebook-views" className="sub-menu-item">Buy Facebook Views</Link></li>*/}
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li className="megamenu-head">SoundCloud Services</li>
                                    <li className="megamenu-head">COMING SOON</li>
                                    {/*<li className={manu === "buy-soundcloud-followers" || "" ? "active" : ""}><Link
                                        to="/buy-soundcloud-followers" className="sub-menu-item">Buy SoundCloud Followers</Link>
                                    </li>
                                    <li className={manu === "buy-soundcloud-likes" || "" ? "active" : ""}><Link
                                        to="/buy-soundcloud-likes" className="sub-menu-item">Buy SoundCloud Likes</Link></li>
                                    <li className={manu === "buy-soundcloud-plays" || "" ? "active" : ""}><Link
                                        to="/buy-soundcloud-plays" className="sub-menu-item">Buy SoundCloud Plays</Link></li>*/}
                                </ul>
                            </li>

                        </ul>
                    </li>

                    <li className={manu === "blog" || "" ? "active" : ""}><Link to="/blog"
                                                                                className="sub-menu-item">Blog</Link>
                    </li>
                    <li className={manu === "about" || "" ? "active" : ""}><Link to="/about"
                                                                                 className="sub-menu-item">About</Link>
                    </li>
                    <li className={manu === "contact" || "" ? "active" : ""}><Link to="/contact" className="sub-menu-item">Contact</Link></li>
                </ul>
            </div>
        </div >
    </nav >
    )
}
