import React, {useState} from 'react'
import {Link} from 'react-router-dom';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';

import {MdKeyboardArrowRight, FaArrowRight, BsCheckCircle, FaArrowDown} from "../../assets/icons/icons"
import {accordionData, pricingTableData} from '../../data/dataTwo';
import shree_logo from "../../assets/images/igchamp/select-package.png";
import google_logo from "../../assets/images/igchamp/put-info.png";
import lenovo_logo from "../../assets/images/igchamp/payment.png";
import circle_logo from "../../assets/images/igchamp/deliver-order.png";
import classic02 from "../../assets/images/igchamp/buy-tiktok-followers.png";
import {Helmet} from "react-helmet";

export default function PageTerms() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [pricingTable, setpricingTable] = useState(pricingTableData.filter((element) => {
        return element.cat === 20;
    }))
    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }
    let accordionData1 = accordionData.filter((x) => x.id < 5);
    let accordionData2 = accordionData.filter((x) => x.id > 4)
    return (
        <>
            <Helmet>
                <title>Buy TikTok followers to boost your presence | IGChamp</title>
                <meta name="description"
                      content="Buy TikTok followers to instantly grow your account’s presence with IG Champ. We’re your one-stop solution for all your TikTok needs."/>
                <meta property="og:title" content="Buy TikTok followers to boost your presence | IGChamp"/>
                <meta property="og:description"
                      content="Buy TikTok followers to instantly grow your account’s presence with IG Champ. We’re your one-stop solution for all your TikTok needs."/>
                <meta name="keywords" content="Buy Tiktok followers"/>
                <meta property="og:image" content={classic02}/>
                <meta property="og:url" content="/buy-tiktok-followers"/>
                <link rel="canonical" href="https://igchamp.com/buy-tiktok-followers"/>
            </Helmet>
            <Navbar/>

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h1 className="text-3xl leading-normal font-semibold">Buy TikTok followers to boost your
                            presence</h1>
                        <p>Buy TikTok followers to instantly grow your account’s presence with IG Champ. We’re your
                            one-stop solution for all your TikTok needs.</p>
                    </div>
                </div>
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link to="/">Home</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight
                            className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link>Tiktok</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight
                            className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600"
                            aria-current="page">Buy TikTok Followers
                        </li>
                    </ul>
                </div>
            </section>

            <div className="relative">
                <div
                    className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Choose
                            Pricing
                            Plan</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Your account should be public!</p>
                    </div>
                    <div id="StarterContent" className="mt-6">
                        <div className="grid lg:grid-cols-4 md:grid-cols-2 mt-8 gap-[30px]">
                            {pricingTable.map((pricing, index) => {
                                return (
                                    <div
                                        className="group border-b-[3px] dark:border-gray-700 p-6 py-8 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow dark:shadow-gray-800 rounded-md bg-gray-50 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-900 h-fit">
                                        <h6 className="font-bold uppercase mb-5 text-indigo-600">{pricing.name}</h6>

                                        <div className="flex mb-5">
                                            <span className="text-xl font-semibold">$</span>
                                            <span className="price text-4xl font-semibold mb-0">{pricing.price}</span>
                                            <span className="text-xl font-semibold self-end mb-1"></span>
                                        </div>

                                        <ul className="list-none text-slate-400">
                                            <li className="mb-1 flex items-center" key={"a" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> 5 Minutes to
                                                24Hours
                                            </li>
                                            <li className="mb-1 flex items-center" key={"b" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Safe &
                                                Secure
                                            </li>
                                            <li className="mb-1 flex items-center" key={"c" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Real and
                                                Active
                                            </li>
                                            <li className="mb-1 flex items-center" key={"d" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Money-Back
                                                Guarantee
                                            </li>
                                        </ul>
                                        <a
                                            className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5"
                                            href={pricing.link}>Buy
                                            Now</a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Boost Tiktok</h6>
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Steps
                            to Place Your Order
                        </h3>
                    </div>

                    <div className="grid grid-cols-1 mt-8">
                        <div
                            className="relative after:content-[''] after:absolute after:top-0 md:after:end-0 md:after:start-0 after:w-px after:h-full md:after:m-auto after:border-s-2 after:border-dashed after:border-gray-200 dark:after:border-gray-700 ms-3 md:ms-0">
                            <div
                                className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="md:text-end md:me-8 relative">
                                        <img src={shree_logo} className="md:ms-auto" alt=""/>
                                    </div>

                                    <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                        <h5 className="title mb-1 font-semibold">Select Your Package</h5>
                                        <p className="mt-3 mb-0 text-slate-400">Choosing the right package to buy TikTok
                                            followers with IG Champ is simple and flexible. We offer a range of follower
                                            packages to suit different needs and budgets. Whether you’re new to TikTok
                                            or want to grow your already established profile, we have the ideal solution
                                            for you. Select the number of followers that aligns with your goals and see
                                            your TikTok presence soar. At IG Champ, we offer well-crafted packages that
                                            deliver maximum impact, ensuring your profile gains the visibility and
                                            engagement it deserves.</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="text-start ms-8 relative md:order-2">
                                        <img src={google_logo} className="md:me-auto" alt=""/>
                                    </div>

                                    <div
                                        className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                        <h5 className="title mb-1 font-semibold">Put Desired Info</h5>
                                        <p className="mt-3 mb-0 text-slate-400">Entering your information to buy TikTok
                                            followers with IG Champ is straightforward and secure. Simply provide your
                                            TikTok username and choose your preferred package—no passwords needed,
                                            ensuring your account's safety and privacy. Our intuitive interface makes
                                            the purchase process quick and easy, requiring just a few clicks. Once your
                                            information is submitted, our team delivers the followers to your profile
                                            promptly. With IG Champ, you can trust that your details are handled
                                            securely and that your TikTok growth is expertly managed.</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="md:text-end md:me-8 relative">
                                        <img src={lenovo_logo} className="md:ms-auto" alt=""/>
                                    </div>

                                    <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                        <h5 className="title mb-1 font-semibold">Pay for your order</h5>
                                        <p className="mt-3 mb-0 text-slate-400">Completing your payment to buy TikTok
                                            followers with IG Champ is quick, secure, and straightforward. You can
                                            choose from different payment options to suit your convenience, ensuring a
                                            seamless transaction process. After selecting your package and entering your
                                            information, proceed to the payment section. Our secure payment options
                                            safeguard your financial details, providing peace of mind. Once your payment
                                            is complete, you will receive a confirmation, and our team will immediately
                                            begin adding followers to your TikTok account.</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="text-start ms-8 relative md:order-2">
                                        <img src={circle_logo} className="md:me-auto" alt=""/>
                                    </div>

                                    <div
                                        className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                        <h5 className="title mb-1 font-semibold">See The Results</h5>
                                        <p className="mt-3 mb-0 text-slate-400">Experience immediate results in your
                                            TikTok growth with IG Champ. Once you make your purchase, an influx of
                                            followers will stream your videos, enhancing both visibility and engagement.
                                            Our instant delivery guarantees swift improvements, expanding your content's
                                            influence and appeal. Monitor your advancement using TikTok analytics and
                                            observe how our services effectively elevate your profile.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative overflow-hidden pb-32 bg-gray-50 dark:bg-slate-800">
                <div className="container grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
                    <div className="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                        <img src={classic02} className="rounded-lg dark:shadow-gray-800 mx-auto"
                             alt="Buy TikTok Followers"/>
                        <div
                            className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl"></div>
                    </div>
                    <div className="lg:ms-8 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                        <h2 className="mb-4 text-2xl leading-normal font-medium">Buy TikTok Followers and Grow
                            Instantly</h2>
                        <p className="text-slate-400">Buy followers on TikTok from IG Champ and watch your profile grow
                            instantly. Our service provides real, active followers that enhance your credibility and
                            visibility on the platform. With more followers, your content gains increased exposure,
                            attracting even more organic engagement. Boost your TikTok presence quickly and effectively
                            with IG Champ's reliable follower packages.</p>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <h3 className="text-xl font-semibold mb-4">Affordable Services for Your TikTok
                                    Needs</h3>
                                <p className="text-slate-400">IG Champ offers affordable services tailored specifically
                                    to meet your TikTok follower needs. Our cost-effective packages are designed to help
                                    you grow your follower count without straining your budget. With IG Champ, you can
                                    enjoy real, active followers that enhance your presence on the platform. Also, our
                                    affordable solutions ensure you get the best value for your investment, driving
                                    genuine engagement and organic growth. </p>
                                <h5 className="text-xl font-semibold mb-4 mt-8">Boost Your Audience and TikTok
                                    Importance</h5>
                                <p className="text-slate-400">Are you looking to enhance your TikTok presence quickly
                                    and effectively? Buy TikTok followers with IG Champ and improve your profile's
                                    visibility and engagement. Our tailored follower packages ensure real, active
                                    followers that boost your credibility and attract more viewers. With seamless
                                    delivery and secure transactions, IG Champ helps you grow your TikTok audience
                                    organically. Increase your influence and reach on TikTok today with our reliable
                                    follower services tailored to meet your needs.</p>
                                <h5 className="text-xl font-semibold mb-4 mt-8">Guaranteed Instant Delivery</h5>
                                <p className="text-slate-400">Experience guaranteed instant delivery with IG Champ's
                                    services. When you buy TikTok followers from us, you can count on prompt and
                                    efficient delivery of your followers to enhance your TikTok profile immediately. Our
                                    streamlined process ensures that followers start flowing to your account swiftly
                                    once you complete your purchase. As a result, your content will reach its targeted
                                    audience, enhancing your profile’s presence and driving attention towards your
                                    videos. This is why you can always trust IG Champ for reliable and quick service
                                    that helps you effortlessly achieve your TikTok growth goals.</p>
                                <h5 className="text-xl font-semibold mb-4 mt-8">100% Safe</h5>
                                <p className="text-slate-400">Ensuring 100% safety is paramount at IG Champ when you buy
                                    TikTok followers from us. We prioritize the security and integrity of your TikTok
                                    account by using only safe and proven methods to deliver followers. Our approach
                                    guarantees that all followers are real and active users, eliminating the risk of
                                    penalties or account compromise. With IG Champ, you can rest assured that your
                                    privacy and account security are protected throughout the process. You can always
                                    count on us to provide a secure and reliable service that enhances your TikTok
                                    presence while safeguarding your online reputation.</p>
                                <h5 className="text-xl font-semibold mb-4 mt-8">TikTok Compliant</h5>
                                <p className="text-slate-400">At IG Champ, we adhere strictly to TikTok's guidelines and
                                    policies to ensure our services are entirely TikTok-compliant. When you buy TikTok
                                    followers from us, you can be confident that our methods align with TikTok's
                                    community standards and terms of service. We prioritize authenticity and integrity,
                                    delivering followers who engage genuinely with your content. Our commitment to
                                    TikTok compliance means that your account remains safe from any risks associated
                                    with non-compliant practices. IG Champ is the name for authenticity, and that’s what
                                    we promise you. </p>
                                <h5 className="text-xl font-semibold mb-4 mt-8">Not Bots</h5>
                                <p className="text-slate-400">When you buy TikTok followers from IG Champ, you get a
                                    guarantee that the followers you buy are not bots. We pride ourselves on delivering
                                    real, active TikTok followers who engage authentically with your content. Unlike
                                    bots or automated accounts, our followers are genuine users who contribute to your
                                    profile's credibility and engagement. By choosing IG Champ, you can be confident
                                    that your TikTok growth is supported by real people, ensuring organic interaction
                                    and lasting impact on the platform. Our services are well trustable and prioritize
                                    quality and authenticity, enhancing your TikTok presence effectively.</p>
                                <h5 className="text-xl font-semibold mb-4 mt-8">Engaging Followers</h5>
                                <p className="text-slate-400">IG Champ offers you engaging followers who actively
                                    interact with your TikTok content. When you buy TikTok followers from us, you invest
                                    in real users who genuinely engage with your videos, comments, and shares. These
                                    followers increase your content's visibility and popularity on TikTok's platform.
                                    Also, our services are designed to connect you with an audience that resonates with
                                    your content, fostering meaningful interactions and potentially increasing your
                                    organic reach. With IG Champ's commitment to delivering engaging followers, you can
                                    expect enhanced engagement metrics and a more vibrant TikTok presence overall.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative md:py-12 py-16">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <div className="md:mt-24 mt-16">
                                    <div className="grid grid-cols-1 pb-8 text-center">
                                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Got
                                            Questions? Get the Answers. </h3>
                                    </div>

                                    <div id="accordion-collapse" data-accordion="collapse"
                                         className="grid md:grid-cols-2 grid-cols-1 mt-8 md:gap-[30px]">
                                        <div>
                                            <div key="11"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(11)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 11 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Why is having more followers on TikTok important?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 11 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 11 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Having more
                                                            followers on TikTok is important for several reasons. First,
                                                            it increases your visibility and exposure on the platform.
                                                            As your follower count grows, more users are likely to
                                                            discover and engage with your content, leading to higher
                                                            views, likes, and comments. This increased engagement boosts
                                                            your profile's credibility and signals to TikTok's algorithm
                                                            that your content is valuable, potentially leading to more
                                                            opportunities to appear on the For You page and reach a
                                                            broader audience. </p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Additionally, a
                                                            larger follower base can
                                                            attract brands and potential collaborators who may be
                                                            interested in partnerships or sponsorships, allowing you to
                                                            monetize your TikTok presence. Overall, more followers on
                                                            TikTok can significantly enhance your influence, reach, and
                                                            opportunities for growth on the platform.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="12"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(12)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 12 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Is buying TikTok followers worth it?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 12 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 12 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Buying TikTok
                                                            followers can be worth it if done strategically and with a
                                                            reputable provider like IG Champ. Here are a few
                                                            considerations: </p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Kickstarting
                                                            Growth:</b> Purchasing followers can quickly increase your
                                                            follower count, jumpstarting your TikTok growth. This
                                                            initial boost can make your profile appear more credible and
                                                            attract organic followers who discover your content through
                                                            increased visibility.</p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Enhancing
                                                            Credibility:</b> A higher follower count can improve your
                                                            credibility on TikTok. Users often perceive accounts with
                                                            more followers as more trustworthy and influential, which
                                                            can lead to increased engagement and opportunities.</p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Potential
                                                            for Monetization:</b> More followers can open doors to
                                                            monetization opportunities such as brand sponsorships,
                                                            collaborations, and participation in TikTok's Creator Fund.
                                                            Brands often look for influencers with a substantial
                                                            follower base to promote their products or services.</p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Organic
                                                            Growth Support:</b> While purchased followers provide an
                                                            initial boost, this strategy must be complemented by
                                                            high-quality content, engagement with your audience, and
                                                            consistency in posting. This combination helps maintain and
                                                            further grow your follower base organically.</p>
                                                        <p className="text-slate-400 dark:text-gray-400">However,
                                                            choosing a provider like IG Champ that offers real, active
                                                            followers is crucial to avoid risks associated with bots or
                                                            fake accounts. Additionally, while buying followers can
                                                            accelerate your growth, genuine engagement and connection
                                                            with your audience remain key to long-term success on
                                                            TikTok.
                                                        </p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Unlike
                                                            unreliable providers that may offer low-quality
                                                            followers or use unethical tactics, IG Champ prioritizes
                                                            authenticity and transparency. You can buy Instagram
                                                            followers confidently from IG Champ, knowing you're
                                                            investing in genuine growth that aligns with Instagram's
                                                            guidelines.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div key="13"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(13)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 13 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>What does “real” followers mean?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 13 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 13 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">“Real”
                                                            followers refer to genuine TikTok users who actively engage
                                                            with content on the platform. IG Champ provides real
                                                            followers who interact authentically with your videos,
                                                            comments, and shares.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="14"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(14)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 14 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>If I have more followers on TikTok, can I also earn through the platform?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 14 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 14 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Yes, having
                                                            more followers on TikTok can lead to earning opportunities
                                                            such as brand sponsorships, partnerships, and monetization
                                                            through TikTok's Creator Fund or through direct
                                                            collaborations with brands.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="15"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(15)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 15 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>How long does it take for the followers to start engaging with my content?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 15 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 15 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">After
                                                            purchasing followers from IG Champ, engagement with your
                                                            TikTok content can begin immediately. Typically, once
                                                            followers are delivered to your account, they may start
                                                            interacting with your videos, liking, commenting, and
                                                            sharing them. The speed and extent of engagement can vary
                                                            based on factors such as the quality of your content, the
                                                            relevance of your posts to your followers, and the activity
                                                            level of the followers themselves. It's common to see
                                                            initial engagement shortly after followers are added,
                                                            helping to increase your content's visibility and overall
                                                            engagement metrics on TikTok.</p>
                                                    </div>
                                                    <ul className="list-none space-x-1 text-slate-400 mt-3">
                                                        <li className="flex items-center mt-2"><FaArrowRight
                                                            className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/> Instant
                                                            Credibility: A higher follower count can give your
                                                            profile instant credibility, making it appear more
                                                            reputable and trustworthy to potential followers and
                                                            customers.
                                                        </li>
                                                        <li className="flex items-center mt-2"><FaArrowRight
                                                            className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/> Increased
                                                            Visibility: With more followers, your posts are more
                                                            likely to appear in other users' feeds, increasing your
                                                            visibility and reach on the platform.
                                                        </li>
                                                        <li className="flex items-center mt-2"><FaArrowRight
                                                            className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/> Social
                                                            Proof: A large following can serve as social proof of
                                                            your popularity and influence, organically attracting
                                                            more followers and engagement.
                                                        </li>
                                                        <li className="flex items-center mt-2"><FaArrowRight
                                                            className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/> Brand
                                                            Growth: For businesses, a larger following can lead to
                                                            increased brand awareness, customer trust, and,
                                                            ultimately, sales and conversions.
                                                        </li>
                                                        <li className="flex items-center mt-2"><FaArrowRight
                                                            className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/> Kickstart
                                                            Growth: Buying followers can kickstart your Instagram
                                                            growth, providing a solid foundation you can continue to
                                                            build organically over time.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="16"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(16)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 16 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>What happens once I complete my order?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 16 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 16 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">After
                                                            completing your order with IG Champ, we immediately begin
                                                            processing your request. You will start seeing an increase
                                                            in followers as we deliver them to your TikTok account
                                                            within the specified timeframe of your chosen package.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="17"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(17)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 17 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>How do I know the exact number of followers I need?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 17 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 17 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Determining the
                                                            exact number of followers you need on TikTok depends on your
                                                            goals and objectives for using the platform. Here are some
                                                            factors to consider when deciding:</p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Reach and
                                                            Visibility:</b> Consider how widely you want your content to
                                                            reach. More followers generally lead to increased visibility
                                                            as your content is shared with a larger audience.</p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Engagement
                                                            Goals:</b> CEvaluate the level of engagement you want to
                                                            achieve with your audience. A larger follower base can
                                                            potentially lead to higher engagement rates, such as likes,
                                                            comments, and shares on your videos.</p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Monetization
                                                            Objectives:</b> If you want to monetize your TikTok presence
                                                            through brand partnerships, sponsorships, or participation
                                                            in TikTok's Creator Fund, having a substantial follower
                                                            count is often a prerequisite.</p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Competitive
                                                            Analysis:</b> Research similar accounts or influencers in
                                                            your niche to gauge typical follower counts. This can
                                                            provide a benchmark for setting realistic goals based on
                                                            industry standards.</p>
                                                        <p className="text-slate-400 dark:text-gray-400"><b>Content
                                                            Strategy:</b> Assess how frequently you plan to post content
                                                            and the themes or topics you intend to cover. Your follower
                                                            count should support your content strategy and help attract
                                                            an audience interested in what you offer.</p>
                                                        <p className="text-slate-400 dark:text-gray-400">Ultimately,
                                                            there is no one-size-fits-all answer to how many followers
                                                            you need. It's essential to align your follower goals with
                                                            your broader TikTok strategy and regularly assess your
                                                            progress to ensure you're effectively meeting your
                                                            objectives.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="18"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(18)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 18 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Can IG Champ help me with boosting my TikTok presence further?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 18 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 18 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Yes, IG Champ
                                                            offers additional services and strategies to boost your
                                                            TikTok presence, such as likes and views packages, as well
                                                            as personalized growth consultations to optimize your
                                                            content strategy.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="19"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(19)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 19 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Can I unfollow the followers I buy if I change my mind?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 19 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 19 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Once followers
                                                            are added to your TikTok account, you can manage your
                                                            follower list as you see fit. However, IG Champ recommends
                                                            focusing on building meaningful engagement with your
                                                            audience to maintain a vibrant TikTok community.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="20"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(20)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 20 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Can I choose where my followers come from?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 20 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 20 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">IG Champ
                                                            provides followers from a diverse range of geographic
                                                            locations. While you cannot specify exact locations, our
                                                            follower packages offer a mix of followers to enhance your
                                                            global presence and reach.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
)
}
