import React, {useState} from 'react'
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";
import Navbar from '../component/Navbar/navbar'
import Footer from '../component/Footer/footer';

import {MdKeyboardArrowRight, FaArrowRight, BsCheckCircle, FaArrowDown} from "../assets/icons/icons"
import {accordionData, consultingAbout, igFollowersFaq, pricingTableData, socialPresence} from '../data/dataTwo';
import shree_logo from "../assets/images/igchamp/select-package.png";
import google_logo from "../assets/images/igchamp/put-info.png";
import lenovo_logo from "../assets/images/igchamp/payment.png";
import circle_logo from "../assets/images/igchamp/deliver-order.png";
import classic02 from "../assets/images/igchamp/buy-instagram-followers.png";
import {BiArrowToRight} from "react-icons/bi";

export default function PageTerms() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [pricingTable, setpricingTable] = useState(pricingTableData.filter((element) => {
        return element.cat === 10;
    }))
    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }
    let accordionData1 = igFollowersFaq.filter((x) => x.id < 5);
    let accordionData2 = igFollowersFaq.filter((x) => x.id > 4)
    return (
        <>
            <Helmet>
                <title>Privacy Policy - IGchamp</title>
                <meta name="description"
                      content=""/>
                <meta property="og:title" content=""/>
                <meta property="og:description"
                      content=""/>
                <meta name="keywords" content=""/>
                <meta property="og:image" content="LINK TO THE IMAGE FILE"/>
                <meta property="og:url" content="/privacy-policy"/>
                <meta name="robots" content="noindex,nofollow"/>
            </Helmet>
            <Navbar/>

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h1 className="text-3xl leading-normal font-semibold">Privacy Policy</h1>
                        <p>IGChamp keeps the privacy of our customers on priority. We consider your privacy on a serious
                            note. To keep your personal data intact, we do all possible measures. Our privacy policy is
                            developed to show you our collection method, use, and disclosure process of your personal
                            information. The data provided by you will be utilized to fulfill your order.</p>
                    </div>
                </div>
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link to="/">Home</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight
                            className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link>Igchamp</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight
                            className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600"
                            aria-current="page">Privacy Policy
                        </li>
                    </ul>
                </div>
            </section>


            <section className="relative md:py-24 py-16">
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">Igchamp keeps the privacy of our customers on
                        priority. We consider your privacy on a serious note. To keep your personal data intact, we do
                        all possible measures. Our privacy policy is developed to show you our collection method, use,
                        and disclosure process of your personal information. The data provided by you will be utilized
                        to fulfill your order.</p>
                </div>
                <div className="container">
                    <h2 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">The Basics</h2>
                </div>
                <div className="container">
                    <ul className="list-none mb-0">
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Now this is a story all
                            about how, my life got flipped-turned upside down
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Your personal information
                            will be solely collected and used either for our specified purposes or for other similar
                            purposes with the consent of the relevant individuals or as per law.
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Our duration of retaining
                            your personal information will be only till the requirement completion phase for the
                            fulfillment of those purposes.
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Our method of collecting
                            personal information is fair and followed by current laws and, with the consensus of the
                            concerned individuals if required.
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/> Personal information
                            collected should be relevant to the purposes being used. Personal data provided by consumers
                            should be accurate, complete, and up-to-date.
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>We make sure of the
                            security and protection of personal information. We have legitimate security protocols to
                            secure your data against loss, theft, copying, misuse, unauthorized access, disclosure, or
                            modification.
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Igchamp readily informs
                            its customers about our policies and practices regarding the management of personal
                            information.
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Our website being SSL
                            encrypted uses secure payment gateways including PayPal and other methods of accepting
                            payments. There is no chance of leaking sensitive financial information via our website.
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>We may collect and store
                            limited payment information from you including payment card type, card expiration date, and
                            the last four digits of your payment card number. But, we do not collect or store full
                            payment card numbers. Our third-party processor for payments processes all the transactions.
                        </li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <h3 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Why Igchamp use the
                        information?</h3>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">Igchamp is striving for Excellency to make a better
                        customer experience for you. To improve our services, we use the information received by us in a
                        variety of ways. The purposes of using your information may include:.</p>
                </div>

                <div className="container">
                    <ul className="list-none mb-0">
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Maintenance and provision
                            of our services
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Analyzing and understanding
                            our audience
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Customization of our
                            services to meet your needs
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Processing of donations and
                            transactions
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Collection of fees and
                            other charges
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Verification and
                            identification along with prevention against fraudulent transactions
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Provision of customer
                            support
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Contacting our customers
                            for feedback or sharing future updates about new products and services
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Allowing our customers to
                            take part in interactive features of our services
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Collecting analysis or
                            valuable information to improve our services
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Detection, prevention, and
                            addressing the technical issues
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Provision of news, special
                            offers, coupon codes, and information about other goods, services, and events like those
                            that you have already purchased or enquired.
                        </li>
                    </ul>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <div className="container">
                    <h3 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">When do we share
                        information?</h3>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">Igchamp maintains the confidentiality of the
                        personal identifiable information of individuals. Our policy clarifies the secrecy of data
                        except in cases where disclosure is required by law or you give us permission for sharing. We
                        may share your information with government officials and law enforcement agencies if required.
                        To protect and defend the rights of Igchamp, its consumers, affiliates, and the public, we may
                        take this action as compulsory. We occasionally may share the collected statistics of user
                        behavior with our partners and other interested parties. The aggregated data shared by us may
                        contain usage patterns, user growth, donation, trends, etc. These shared stats will never show
                        an individual person’s information or identification. Please be aware that any personal
                        information voluntarily shared by you to display in any public location while using our platform
                        will be available publicly for anyone to collect and use it.</p>
                    <p className="text-80  mx-auto mt-5 text-auto">Igchamp does not sell, lease, share, rent, or
                        otherwise provide any personal identifiable information to any companies or persons outside the
                        Igchamp for marketing purposes. We use third-party service providers or affiliates to assist
                        us in providing services to you. They may assist us in providing marketing, advertising,
                        communications, and service optimization, processing payment methods, provision of customer
                        service, collecting and analyzing data, and administering consumer surveys. While processing
                        these services, these service providers can access your personal information. Igchamp does not
                        authorize any third-party service provider to use or disclose your personal information except
                        in connection with providing their services. They are bound by confidentiality agreements
                        limiting the use of such information. In case, if Igchamp or some or all assets about our
                        services are merged with or acquired by a third party entity, we reserve the right to transfer
                        or provide access to any information collected by us from users.</p>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <div className="container">
                    <h3 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">What type of personal data is
                        collected by us?</h3>
                </div>
                <div className="container">
                    <h4 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">1. Personal Information</h4>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">You can either visit our website without informing us
                        or revealing your identification information. But, whenever you use any of the features of
                        Igchamp or wish to use our services, we will ask you to provide some personal information.
                        This information may include your name, your email address, etc. when you fill in your personal
                        information while making a purchase from us, we receive and store it. This may cover social
                        handle name, and email address. Likewise, we collect your information when you fill up the
                        contact us form. However, Igchamp does not collect or store any kind of payment
                        information.</p>
                </div>
                <div className="container">
                    <h4 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">2. Media</h4>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">While uploading images to our website, you should be
                        careful about it. It is not recommended to upload images with embedded location data (EXIF GPS)
                        included. Any website visitor can download your images and extract any location data from those
                        on the website.</p>
                </div>
                <div className="container">
                    <h4 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">3. Comments</h4>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">We collect the data when visitors leave comments on
                        our site. The data collection includes data in the comments form, visitor’s IP address, and
                        browser user agent string. The purpose of this data collection is to detect spam.</p>
                </div>
                <div className="container">
                    <h4 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">4. Data collection from
                        secondary content</h4>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">Our website articles may contain secondary content in
                        the embedded form such as images, videos, articles, etc. This secondary content is actually from
                        other websites. And when you click on those embedded content, these sites behave in the same
                        manner as you visit them directly. These websites may collect your information, use cookies and
                        embed extra third-party tracking. These can also monitor your activities on the embedded
                        content. They can track your interaction with the embedded content if you created an account or
                        logged in to these websites.</p>
                </div>
                <div className="container">
                    <h4 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">5. Automatic Data
                        Collection</h4>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">While visiting our website, our servers automatically
                        record the data sent by your browser. The information in this data may include;</p>
                </div>
                <div className="container">
                    <ul className="list-none mb-0">
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Your device’s IP address
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Type and version of your
                            browser and operating system
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Language preferences
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Pages on our site visited
                            by you and your time spent on those pages
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Data you search on our
                            website, access time, dates, and other statistics
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>The webpage you visited
                            before visiting our site
                        </li>
                    </ul>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">The purpose of the automatic data collection is the
                        identification of the potential abuse cases and the establishment of statistical information
                        about our website usage. There is no other way to collect statistical data for the
                        identification of any particular user of the system.</p>
                </div>
                <div className="container">
                    <h3 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">How long do we retain your
                        data?</h3>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">We store the personal data of our users that register
                        on our site by providing their user profiles. Any user can overview, edit or delete their
                        personal information at any time excluding their user name. Administrators of the website can
                        also see and edit that information.</p>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">The comments you leave on our site, along with their
                        metadata are retained for an indefinite period. We retain these to recognize and approve any
                        follow-up comments automatically rather than keeping them in a moderation queue.</p>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">Igchamp retains your personal data until it is
                        required for the fulfillment of purposes as described in our Privacy Policy. We will retain your
                        personal data as long as is necessary to comply with our legal obligations. We retain your
                        information to resolve disputes, and enforce our legal agreements and policies.</p>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">We will also retain Usage Data for the purpose of
                        internal analysis. The retention duration of usage data is generally shorter, except in the
                        cases where this data is being used for longer time periods including:</p>
                </div>
                <div className="container">
                    <ul className="list-none mb-0">
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Strengthening our security
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Improving the functionality
                            of our services
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>For legal purposes
                        </li>
                    </ul>
                </div>
                <div className="container">
                    <h3 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">General Data Protection
                        Regulation (GDPR)</h3>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">As a user of our website, if you are a resident of
                        the European Economic Area (EEA), you have certain data protection rights. If you wish to be
                        informed what Personal Information we hold about you and if you want it to be removed from our
                        systems, please contact us. Our Privacy Policy is generated with the aid of the GDPR Privacy
                        Policy Generator and the App Privacy Policy Generator.</p>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">In certain conditions, you have the following data
                        protection rights:</p>
                </div>
                <div className="container">
                    <ul className="list-none mb-0">
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>The right to access,
                            update, or delete the information we have on you.
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>The right to object
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>The right of rectification
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>The right to data
                            portability
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>The right to withdraw
                            consent
                        </li>
                    </ul>
                </div>
                <div className="container">
                    <h3 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Privacy rights under California
                        Consumer Privacy Act (CCPA)</h3>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">Among the other rights, CCPA gives rights to
                        California consumers to:</p>
                </div>
                <div className="container">
                    <ul className="list-none mb-0">
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Request to disclose the
                            categories and specific pieces of personal data about consumers collected by a business.
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Request a business to not
                            sell the consumer’s personal information to anyone else.
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Request for deletion of any
                            personal data of the consumer collected by a business.
                        </li>
                    </ul>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">While requesting CCPA rights, Igchamp team will
                        respond to you under one month. If you would like to exercise any of these rights, please
                        contact us.</p>
                </div>
                <div className="container">
                    <h4 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Log Files</h4>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">Igchamp follows a standard protocol of using log
                        files. When the customers visit the website, these files log the visitors. This process is
                        performed by all the hosting companies and is a part of hosting services’ analytics. The
                        following set of information is collected by log files:</p>
                </div>
                <div className="container">
                    <ul className="list-none mb-0">
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Internet protocol (IP)
                            addresses
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Type of browser used
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Internet Service Provider
                            (ISP)
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Date and time stamp
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Referring/exit pages
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Number of clicks
                        </li>
                    </ul>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">All this data collected by log files is not
                        associated or linked with any information that is personally identifiable. The purposes of data
                        collection by these log files may include:</p>
                </div>
                <div className="container">
                    <ul className="list-none mb-0">
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Analyzing trends
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Administering the site
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Tracking the user’s
                            movement on the website
                        </li>
                        <li className=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Collecting demographic
                            information
                        </li>
                    </ul>
                </div>
                <div className="container">
                    <h4 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Cookies and Web Beacons</h4>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">Igchamp uses ‘cookies’ just like any other website.
                        The main purpose of using cookies is to store information. This information may include
                        visitors’ preferences, and the pages on the websites visited or accessed by the visitor. This
                        information is being utilized for optimizing the users’ experience. And for this purpose, we
                        customize our web page content based on the type of browser being used by visitors and/or other
                        information. You have options to disable cookies via your individual browser options.</p>
                </div>
                <div className="container">
                    <h4 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Children’s Policy</h4>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">Children’s protection while using the internet is
                        also our priority. Igchamp encourages parents and guardians to observe, monitor, participate
                        and guide their children’s online activity. We do not knowingly collect any personal
                        identifiable information from children under the age of 13. If you think that your child
                        provided us with this kind of information while using our website, we strongly recommend you to
                        contact us immediately. Our team will try our best to take prompt action to remove such data
                        from our records.</p>
                </div>
                <div className="container">
                    <h4 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Third-Party Privacy
                        Policies</h4>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">We; at Igchamp clarify that our Privacy Policy is
                        not applicable to other advertisers or websites. We recommend you to consult the respective
                        Privacy Policies of these third-party ad servers for more detailed information. Their policies
                        and instructions will show how to opt-out of certain options. However, if you are unable to
                        understand any point of our privacy policy you can contact us for guidance.</p>
                </div>
                <div className="container">
                    <h4 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Online Privacy Policy Only</h4>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">Our Privacy Policy is only applicable to our online
                        activities. Our policy is valid for online visitors to our website regarding the information
                        shared by them or collected by Igchamp. This policy does not apply to any information
                        collected offline or via channels other than this website.</p>
                </div>
                <div className="container">
                    <h4 className="mb-4 text-2xl lg:leading-normal leading-normal mt-3">User Consent</h4>
                </div>
                <div className="container">
                    <p className="text-80  mx-auto mt-5 text-auto">By using our website, you hereby consent to our
                        Privacy Policy and agree to its terms.</p>
                </div>
            </section>


            <Footer/>
        </>
    )
}
